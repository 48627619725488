
/* Hide scrollbar for report list */
/* Hide scrollbar for Chrome, Safari and Opera */
.reportListScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox Note: this class use in multiple components*/
.reportListScroll {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

/* to handle kanban simplebar */
/* .simplebar-placeholder {
  display: none;
  width: 0px;
  height: 0px;
} */

/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* lazy image */
@import 'react-lazy-load-image-component/src/effects/blur.css';

/* lightbox */
@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';
@import 'yet-another-react-lightbox/plugins/thumbnails.css';

/* editor */
@import 'react-quill/dist/quill.snow.css';
